// scss-docs-start color-variables
$chart1: rgba(246, 10, 30, 0.75) !default;
$chart2: rgba(232, 153, 5, 0.75) !default;
$chart3: rgba(255, 243, 5, 0.75) !default;
$chart4: rgba(32, 180, 27, 0.75) !default;
$chart5: rgba(22, 158, 133, 0.75) !default;
$chart6: rgba(0, 153, 230, 0.75) !default;
$chart7: rgba(13, 109, 253, 0.75) !default;
$chart8: rgba(68, 77, 143, 0.75) !default;
$chart9: rgba(34, 0, 254, 0.75) !default;
$chart10: rgba(190, 60, 207, 0.75) !default;
$chart11: rgba(214, 30, 91, 0.75) !default;
$chart12: rgba(255, 138, 5, 0.75) !default;
$chart13: rgba(255, 218, 106, 0.75) !default;
$chart14: rgba(26, 135, 84, 0.75) !default;
$chart15: rgba(21, 135, 148, 0.75) !default;
$chart16: rgba(124, 118, 209, 0.75) !default;
$chart17: rgba(158, 63, 217, 0.75) !default;
$chart18: rgba(249, 54, 190, 0.75) !default;
$chart19: rgba(220, 53, 70, 0.75) !default;
$chart20: rgba(255, 193, 8, 0.75) !default;
$chart21: rgba(0, 237, 254, 0.75) !default;
$chart22: rgba(166, 233, 213, 0.75) !default;
$chart23: rgba(192, 137, 219, 0.75) !default;
$chart24: rgba(111, 66, 194, 0.75) !default;
$chart25: rgba(239, 173, 206, 0.75) !default;
$chart26: rgba(227, 93, 106, 0.75) !default;
$chart27: rgba(241, 174, 181, 0.75) !default;
$chart28: rgba(214, 51, 132, 0.75) !default;
$chart29: rgba(156, 64, 73, 0.75) !default;
$chart30: rgba(227, 107, 141, 0.75) !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$chart-colors: (
  'chart1': $chart1,
  'chart2': $chart2,
  'chart3': $chart3,
  'chart4': $chart4,
  'chart5': $chart5,
  'chart6': $chart6,
  'chart7': $chart7,
  'chart8': $chart8,
  'chart9': $chart9,
  'chart10': $chart10,
  'chart11': $chart11,
  'chart12': $chart12,
  'chart13': $chart13,
  'chart14': $chart14,
  'chart15': $chart15,
  'chart16': $chart16,
  'chart17': $chart17,
  'chart18': $chart18,
  'chart19': $chart19,
  'chart20': $chart20,
  'chart21': $chart21,
  'chart22': $chart22,
  'chart23': $chart23,
  'chart24': $chart24,
  'chart25': $chart25,
  'chart26': $chart26,
  'chart27': $chart27,
  'chart28': $chart28,
  'chart29': $chart29,
  'chart30': $chart30,
) !default;
// scss-docs-end colors-map
:root {
  @each $color, $value in $chart-colors {
    --#{$color}: #{$value};
  }
}
